import React, { useContext, useEffect } from "react"
import { BookingContext } from "../providers/booking"

const DataLayer = ({ga4=false}) => {

    const { booking } = useContext(BookingContext)

    useEffect(() => {

        {
            if(ga4) {
                window.dataLayer.push({
                    event: 'purchase',
                        ecommerce: {
                            purchase: {
                                'transaction_id': booking.id,
                                'value': booking.price,
                                'items': [{
                                    'item_id': booking.ref,
                                    'item_price': booking.prices.totalPrice,
                                    'quantity': 1,
                                }]
                            }
                        }
                })
            }
            else {
                window.dataLayer.push({
                    'event': 'purchase',
                    'transactionId': booking.id,
                    'transactionTotal': booking.prices.totalPrice,
                    'transactionProducts': [{
                    'sku': booking.ref,
                    'name': booking.propName,
                    'price': booking.prices.totalPrice,
                    'quantity': 1
                    }]
                });                
            }
        }

    }, [])

    return null;

}
// GA Universal
// dataLayer.push({
//     'event': 'purchase',
//     'transactionId': '1234',
//     'transactionTotal': 38.26,
//     'transactionProducts': [{
//       'sku': 'DD44',
//       'name': 'T-Shirt',
//       'price': 11.99,
//       'quantity': 1
//     }]
//    });

// GA 4
// window.dataLayer.push({
//     event: 'purchase',
//         ecommerce: {
//             purchase: {
//                 'transaction_id': booking.id,
//                 'value': booking.price,
//                 'items': [{
//                     'item_id': booking.ref,
//                     'item_price': booking.prices.totalPrice,
//                     'quantity': 1,
//                 }]
//             }
//         }
// })

export default DataLayer