import styled from 'styled-components';

const Wrapper = styled.div`

  input, div.error, input[type=checkbox] + div {
    margin-bottom: 0.5rem;
  }

  div.error {
    color: ${props => props.theme.colors.error};
    ${props => props.theme.fonts.smallerFontSize};
  }
`

export default Wrapper
