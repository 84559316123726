import React, { useContext } from "react"
import styled from "styled-components"
import { BookingContext } from "../providers/booking"
import Heading from "../../../utils/heading"

import DataLayer from "./dataLayer"

const Wrapper = styled.div`
  ${props => props.theme.wrappersContainers.primaryWrapper};
  margin-bottom:3rem;
`

// const Button = styled.button`
//   ${props => props.theme.buttons[props.action]}
// `

const CheckoutConfirm = ({ path, title, back }) => {
  if (typeof window !== "undefined") {
    window.history.pushState({}, null, path)
  }

  const { booking } = useContext(BookingContext)

  return (
    <Wrapper>
      <DataLayer />
      <Heading type="title" as="h2" color={props => props.theme.colors.secondary}>
        {title}
      </Heading>
      <p>Thank you for booking with Sweetcombe Holiday Cottages.</p>
      <p>Your booking reference is <strong>{booking.id}</strong></p>
      <p>Please check your inbox for your booking confirmation with more details about your booking.</p>
      {/* <Button type="submit" action="primary" onClick={back}>Back</Button> */}
    </Wrapper>
  )
}

export default CheckoutConfirm
