import React from "react"
import CheckoutConfirm from "../components/checkout/steps/confirm"
import Wrapper from "../components/checkout/wrapper"

const Checkout = ({ booking }) => (
  <Wrapper>
    <CheckoutConfirm path="confirm" title="Booking confirmation" />
  </Wrapper>
)

export default Checkout
